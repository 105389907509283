"use strict";
// not yet implemented nor fully tested
const config = [
	{
		issuer: "US date",
		patterns: [{ length: 8, mask: [2, 2, 4] }],
	},
	
];

const getDate = (number) => {
	return config[0];
};

const getPatter = (number) => {
	const date = getDate(number);
	const pattern = { mask: [3, 3, 4] };

	if (!date) return pattern;

	const patterns = date.patterns;
	for (let j = 0; j < patterns.length; j++) {
		if (number.length <= patterns[j].length) {
			return patterns[j];
		}
	}

	return pattern;
};

export class USDate {
	number: string;
	date: any;
	pattern: any;

	constructor(value) {
		this.number = value;
		this.date = getDate(value);
	}

	get() {
		return this.date;
	}

	format() {
		if (!this.number) return;
		//  regular expression /\D/g matches all non-digits
		// inbound date in format of yyyy-mm-dd
		let date = this.number.toString().replace(/\D/g, "");
		const pattern = getPatter(date);

		if (!pattern) return this.number;

		const mask = pattern.mask;
		let ret = [];
		ret.push(mask[2]);
		ret.push("/");
		ret.push(mask[1]);
		ret.push("/");
		ret.push(mask[0]);


		return ret.join(" ");
	}
}
