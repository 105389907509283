import { Injectable } from '@angular/core';
import {
	FinancialsFiscalYearsResource,
	FinancialsFiscalYear,
	FinancialsPeriod,
	Entity,
	ResourceParams,
	FinancialsPeriodsResource,
} from '@towncloud/thor-api';
@Injectable({
  providedIn: 'root',
})
export class FinancialsCurrentFiscalYearService {
	currentFiscalYear = new FinancialsFiscalYear();
	currentPeriod = new FinancialsPeriod();
	currentEntity = new Entity();
	fiscalYearValue = 0;
	periodValue = 0;
	currentEffectiveDate = '';
	startOfEffectiveMonth = '';
	
	constructor(
		private periodsResource: FinancialsPeriodsResource,
		private fiscalYearsResource: FinancialsFiscalYearsResource,
	) {}

	setFiscalYear(year) {
		// console.log(year);
		if (this.currentFiscalYear != year) {
			this.currentFiscalYear = year;
			this.fiscalYearValue = this.currentFiscalYear.fiscalYear;
			sessionStorage.setItem('asOfFiscalYear', JSON.stringify(this.currentFiscalYear.fiscalYear));
			// console.log('set year');
		}
	}

	setPeriod(period) {
		// console.log('setPeriod');
		// console.log(period);
		if (this.currentPeriod != period) {
			this.currentPeriod = period;
			this.periodValue = this.currentPeriod.periodNumber;
			sessionStorage.setItem('asOfPeriod', JSON.stringify(this.currentPeriod.periodNumber));
			// console.log(this.currentPeriod.endDate);
			// console.log('asOfDate');
			this.setEffectiveDate(this.currentPeriod.endDate);
		}
	}

	async getFiscalYear() {
		this.getCurrentEntityId();
		if (this.currentFiscalYear && this.currentFiscalYear.id && this.currentFiscalYear.financialsEntityId == this.currentEntity.id) {
			return this.currentFiscalYear;
		} else {
			if (sessionStorage.getItem('asOfFiscalYear')) {
				this.fiscalYearValue = JSON.parse(sessionStorage.getItem('asOfFiscalYear'));
				await this.getEntityFiscalYear()
				return this.currentFiscalYear;
			}
		}
	}

	async getEntityFiscalYear() {
		const params = new ResourceParams({
			filters: [
				[
					{
						property: 'fiscalYear',
						operator: 'eq',
						value: this.fiscalYearValue,
					},
				],
			],
		});

		const data = await this.fiscalYearsResource.get(params);
		this.currentFiscalYear = data.items[0];
	}	
	
	async getPeriod() {
		this.getCurrentEntityId();
		// console.log(this.currentEntity);
		// console.log(this.currentPeriod);
		// console.log(this.currentFiscalYear);
		// console.log(this.currentEffectiveDate);
		if (this.currentPeriod && this.currentPeriod.id && this.currentPeriod.financialsEntityId == this.currentEntity.id) {
			return this.currentPeriod;
		} else {
			if (sessionStorage.getItem('asOfPeriod') && this.currentEffectiveDate) {
				this.periodValue = JSON.parse(sessionStorage.getItem('asOfPeriod'));
				await this.getEntityPeriod();
				return this.currentPeriod;
			} else { if (sessionStorage.getItem('asOfPeriod') && sessionStorage.getItem('asOfFiscalYear')) {
				this.periodValue = JSON.parse(sessionStorage.getItem('asOfPeriod'));
				await this.getFiscalYear();
				await this.getEntityYearPeriod();
				this.setStartOfEffectiveMonth();
				this.currentEffectiveDate = this.currentPeriod.endDate;
				return this.currentPeriod;
				} else {
					return undefined
				}
			}
		}
	}

	getPeriodForDate() {
		const params = new ResourceParams({
			expand: ['fiscalYear'],
			filters: [
				[
					{
						property: 'financialsEntityId',
						operator: 'eq',
						value: this.currentEntity.id,
					},
					{
						property: 'startDate',
						operator: 'lte',
						value: this.currentEffectiveDate,
					},
					{
						property: 'endDate',
						operator: 'gte',
						value: this.currentEffectiveDate,
					},
				],
			],
		});

		this.periodsResource.get(params).then(data => {
			// console.log(data);
			this.currentPeriod = data.items[0];
			sessionStorage.setItem('asOfPeriod', JSON.stringify(this.currentPeriod.periodNumber));
		});
	}

	async getEntityPeriod() {
		const params = new ResourceParams({
			expand: ['fiscalYear'],
			filters: [
				[
					{
						property: 'startDate',
						operator: 'lte',
						value: this.currentEffectiveDate,
					},
					{
						property: 'endDate',
						operator: 'gte',
						value: this.currentEffectiveDate,
					},
					{
						property: 'periodNumber',
						operator: 'eq',
						value: this.periodValue
					},
				],
			],
		});
		
		const data = await this.periodsResource.get(params);
		// console.log(data);
		this.currentPeriod = data.items[0];
	}

	async getEntityYearPeriod() {
		const params = new ResourceParams({
			expand: ['fiscalYear'],
			filters: [
				[
					{
						property: 'financialsFiscalYearId',
						operator: 'eq',
						value: this.currentFiscalYear.id,
					},
					{
						property: 'periodNumber',
						operator: 'eq',
						value: this.periodValue
					},
				],
			],
		});
		
		const data = await this.periodsResource.get(params);
		// console.log(data);
		this.currentPeriod = data.items[0];
	}

	setEffectiveDate(asOfDate) {
		// console.log(asOfDate);
		// console.log(this.currentEffectiveDate);
		if (this.currentEffectiveDate != asOfDate) {
			this.currentEffectiveDate = asOfDate;
			sessionStorage.setItem('asOfDate', JSON.stringify(this.currentEffectiveDate));
			this.startOfEffectiveMonth = this.setStartOfEffectiveMonth();
			this.getPeriodForDate();
			// console.log('set date');
		}
	}

	getEffectiveDate() {
		if (this.currentEffectiveDate) {
			return this.currentEffectiveDate;
		} else {
			if (sessionStorage.getItem('asOfDate')) {
				this.currentEffectiveDate = JSON.parse(sessionStorage.getItem('asOfDate'));
				this.startOfEffectiveMonth = this.setStartOfEffectiveMonth();
				return this.currentEffectiveDate;
			}
		}
	}

	getStartOfEffectiveMonth() {
		if (this.currentEffectiveDate) {
			return this.startOfEffectiveMonth;
		}
	}

	getCurrentEntityId() {
		if (sessionStorage.getItem('entity')) {
			this.currentEntity = JSON.parse(sessionStorage.getItem('entity'));
		}
	}

	private setStartOfEffectiveMonth() {
		const effYear = this.currentEffectiveDate.substring(0,4);
		const effMonth = this.currentEffectiveDate.substring(5,7);
		const effDay = '01';

		this.startOfEffectiveMonth = effYear + '-' + effMonth + '-' + effDay;
		return this.startOfEffectiveMonth;
	}


}
