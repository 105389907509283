import {
	Component,
	ViewEncapsulation,
	Input,
	Output,
	EventEmitter,
	ElementRef,
	ViewChild
} from '@angular/core';

import {
	animate,
	trigger,
	state,
	transition,
	style,
	group,
} from '@angular/animations';

import { Subscription } from 'rxjs';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';

import { Grid, GridColumn, GridFilter, GridSetting, Field } from '../../../../dataTypes/dataTypes';

import {
	GridSettingsResource,
	DownloadResource,
	CurrentUserResource,
} from '../../../../resources/resources';

import { ResourceParams, DataService, User, City } from '@towncloud/thor-api';

import { CurrentUserService, CurrentTownService } from '../../../../services/services';

import * as moment from 'moment';

@Component({
	selector: 'grid',
	templateUrl: './grid.html',
	styleUrls: ['./grid.scss'],
	encapsulation: ViewEncapsulation.None,
	animations: [
		trigger('showFilter', [
			state(
				'true',
				style({
					height: '*',
					overflow: 'visible',
				})
			),
			state(
				'false',
				style({
					height: '0',
				})
			),
			transition('* => *', animate('250ms ease-in')),
		]),

		trigger('showSettings', [
			state(
				'true',
				style({
					height: '*',
				})
			),
			state(
				'false',
				style({
					height: '0',
				})
			),
			transition('true => false', animate('250ms ease-in')),
			transition('false => true', animate('250ms ease-out')),
		]),

		trigger('showSave', [
			state(
				'true',
				style({
					display: 'block',
				})
			),
			state(
				'false',
				style({
					display: 'none',
				})
			),
			transition('false => true', animate('100ms ease-out')),
		]),

		trigger('showReset', [
			state(
				'true',
				style({
					display: 'block',
				})
			),
			state(
				'false',
				style({
					display: 'none',
				})
			),
			transition('false => true', animate('100ms ease-out')),
		]),

		trigger('showDownload', [
			state(
				'true',
				style({
					display: 'block',
				})
			),
			state(
				'false',
				style({
					display: 'none',
				})
			),
			transition('false => true', animate('100ms ease-out')),
		]),
	],
})
export class GridComponent {
	@Input() settings: Grid = new Grid();

	@Output() clickRow: EventEmitter<any> = new EventEmitter();
	@Output() sort: EventEmitter<any> = new EventEmitter();
	@Output() pageUpdated: EventEmitter<any> = new EventEmitter();
	@Output() changeFilter: EventEmitter<any> = new EventEmitter();
	@Output() changeRows: EventEmitter<any> = new EventEmitter();

	loaded = false;

	gridSettings = {
		columns: [],
		filters: [],
	};

	defaultVisibility: Array<any> = [];

	currentPage: Field = new Field({
		type: 'number',
		value: 1,
		min: 1,
	});

	reportName = new Field({
		type: 'input',
		label: 'Name',
	});

	shareReport = new Field({
		type: 'checkbox',
		label: 'Share',
		id: 'shareReport',
	});

	columnSettings: Array<GridColumn> = [];
	showColumnSettings = false;
	showFilter: string;

	showSettings: string;
	showSave: string;
	showReset: string;
	showDownload: string;
	totalPages: number;

	columns: Array<any> = [];

	col1size: number;
	col2size: number;
	col3size: number;
	start1: number;
	start2: number;
	start3: number;
	end1: number;
	end2: number;
	end3: number;
	rem: number;

	dragElement: any;

	checkboxColumn: Array<any>;

	selectedReport: any;
	user: User = new User();
	userSubscription: Subscription;

	showReportErrorsModal = false;
	filterErrors: Array<string>;
	filtersApplied = 0;

	city: City = new City;
	columnSettingsClass = 'position-bottom';

	@ViewChild('settingsButton') settingsButton: ElementRef;

	constructor(
		private gridSettingsResource: GridSettingsResource,
		private downloadResource: DownloadResource,
		private dataService: DataService,
		private currentUserResource: CurrentUserResource,
		private currentUserService: CurrentUserService,
		private currentTownService: CurrentTownService,
		private http: HttpClient,
		private elementRef: ElementRef
	) {
		this.hideAllHeaderExpands();

		this.getCurrentUser();

		this.userSubscription = this.currentUserService
			.getChangeEmitter()
			.subscribe((user) => {
				if (user.id) {
					this.getCurrentUser();
				}
			});
	}

	ngOnInit() {
	}

	ngOnDestroy() {
		this.userSubscription.unsubscribe();
	}

	ngOnChanges() {
		if (this.settings.showReports && this.settings.id) {
			this.updateSavedSettingsList();
		}
		//  added reset setting to grid to enable entity based grids to remove the cached value of the grid so that any entity based changes would get applied
		if (this.settings.reset && this.settings.id) {
			this.removeStateFromSession();
		}

		this.loadSettings();

		this.applyRowSettings();

		this.get();
	}

	async updateSavedSettingsList() {
		if (!this.settings.id) {
			return;
		}
		this.city = await this.currentTownService.get();

		this.settings.reports = new Field({
			type: 'select',
			options: [],
			optionLabel: 'name',
			placeholder: 'Select report',
		});

		const params = new ResourceParams({
			filters: [
				[
					{
						property: 'gridId',
						operator: 'eq',
						value: this.settings.id,
					},
					{
						property: 'city',
						operator: 'eq',
						value: this.city.slug,
					},
					{
						property: 'userId',
						operator: 'eq',
						value: this.user.id,
					},
				],
				[
					{
						property: 'gridId',
						operator: 'eq',
						value: this.settings.id,
					},
					{
						property: 'city',
						operator: 'eq',
						value: this.city.slug,
					},
					{
						property: 'share',
						operator: 'eq',
						value: true,
					},
				],
			],
		});

		const res: any = await this.gridSettingsResource.get(params);

		if (res.items) {
			this.settings.reports.options = res.items;
		}

		return res;
	}

	applyRowSettings() {
		this.settings.columns.forEach((column, key) => {
			if (column.type === 'checkbox') {
				this.settings.rows.forEach((row, rowIndex) => {
					const field = new Field({
						type: 'checkbox',
						id: column.field + '_' + rowIndex,
						value: eval(`row.${column.field}`),
					});
					eval(`row.${column.field} = field`);
				});
			}
		});
	}

	getPropertyValue(row, property) {
		return eval(`row.${property}`);
	}

	moveColumn(event) {
		console.log(event);
	}

	evaluateCell(row, cell) {
		if (cell.calculateValue) {
			return cell.calculateValue(row);
		} else if (cell.field) {
			try {
				return eval('row.' + cell.field);
			} catch (e) {
				return undefined;
			}
		}

		return '';
	}

	selectLine(line, column, e) {
		this.clickRow.emit({ row: line, column: column, event: e });
	}

	selectColumn(column) {
		this.settings.offset = 0;

		this.gridSettings.columns.forEach((c) => {
			if (c.field !== column.field) {
				c.sortOrder = undefined;
			}
		});

		if (column.sortOrder) {
			if (column.sortOrder === 'ASC') {
				column.sortOrder = 'DESC';
			} else {
				column.sortOrder = 'ASC';
			}
		} else {
			this.clearSorting();
			column.sortOrder = 'ASC';
		}

		this.currentPage.value = 1;

		this.sort.emit(column);

		this.get();
	}

	setColumnSort(column) {
		if (column.sortOrder === undefined) {
			column.sortOrder = 'ASC';
		} else if (column.sortOrder === 'ASC') {
			column.sortOrder = 'DESC';
		} else if (column.sortOrder === 'DESC') {
			column.sortOrder = undefined;
		}
	}

	clearSorting() {
		this.settings.columns.forEach((column: GridColumn) => {
			column.sortOrder = undefined;
		});
	}

	getTotalPages() {
		return Math.ceil(this.settings.count / this.settings.itemsPerPage);
	}

	updateCurrentPage(page) {
		if (page < 1) {
			this.currentPage.value = 1;
		} else if (page > this.getTotalPages()) {
			this.currentPage.value = this.getTotalPages();
		} else {
			this.currentPage.value = page;
		}

		this.updatePage();
	}

	updatePage() {
		const offset = (this.currentPage.value - 1) * this.settings.itemsPerPage;
		const limit = this.settings.itemsPerPage;

		this.settings.offset = offset;
		this.settings.limit = limit;

		this.pageUpdated.emit({
			page: this.currentPage.value,
			offset: offset,
			limit: limit,
		});

		this.get();
	}

	hideAllHeaderExpands() {
		this.showFilter = 'false';
		this.showSettings = 'false';
		this.showSave = 'false';
		this.showReset = 'false';
		this.showDownload = 'false';
	}

	updateFilter(filter = undefined) {
		this.currentPage.value = 1;
		this.settings.offset = 0;
		this.filtersApplied = 0;

		// this.resetReportForm();

		this.get();
		this.showFilter = 'false';
	}

	setFilterColumnSizes() {
		this.col1size = 0;
		this.col2size = 0;
		this.col3size = 0;
		this.start1 = 0;
		this.start2 = 0;
		this.start3 = 0;
		this.end1 = 0;
		this.end2 = 0;
		this.end3 = 0;
		this.rem = 0;

		if (this.gridSettings.filters.length > 10) {
			// spread filter over 3 columns
			this.col1size = (this.gridSettings.filters.length / 3) | 0;
			this.rem = (this.gridSettings.filters.length % 3);

			this.col2size = this.col1size;
			this.col3size = this.col1size;
			if (this.rem > 0) {
				this.col1size += 1;
				this.rem = this.rem - 1;
				if (this.rem > 0) {
					this.col2size += 1;
				}
			}
			this.start1 = 0;
			this.end1 = this.col1size;
			this.start2 = this.end1;
			this.end2 = this.end1 + this.col2size;
			this.start3 = this.end2;
			this.end3 = this.start3 + this.col3size;

		}

		if (this.gridSettings.filters.length > 5 && this.gridSettings.filters.length <= 10) {
			// spread filter over 2 columns
			this.col1size = this.gridSettings.filters.length / 2;
			this.rem = this.gridSettings.filters.length / 2;
			this.col2size = this.col1size;
			if (this.rem > 0) {
				this.col1size += 1;
			}
			this.start1 = 0;
			this.end1 = this.col1size;
			this.start2 = this.end1;
			this.end2 = this.end1 + this.col2size;
		}

		if (this.gridSettings.filters.length <= 5) {
			// use just 1 column
			this.col1size = this.gridSettings.filters.length;
			this.start1 = 0;
			this.end1 = this.col1size;
		}

	}

	resetFilters() {
		this.gridSettings.filters.forEach((filter) => {
			filter.field.value = undefined;
		});

		this.updateFilter();
	}

	toggleFilters() {
		if (this.showFilter === 'true') {
			this.showFilter = 'false';
		} else {
			this.hideAllHeaderExpands();
			this.showFilter = 'true';
		}
	}

	updateSettings() {
		this.gridSettings.columns = [...this.columnSettings];

		this.resetReportForm();
		this.get();
		this.toggleSettings();
	}

	resetReportForm() {
		this.selectedReport = undefined;
		this.settings.reports.value = undefined;

		this.reportName.value = undefined;
		this.shareReport.value = undefined;
		this.shareReport.disabled = false;
	}

	resetSettings() {
		this.columnSettings = [...this.settings.columns];

		this.columnSettings.forEach((column) => {
			this.defaultVisibility.forEach((isVisible) => {
				if (column.label === isVisible.label) {
				column.visible = isVisible.visible;
				return;
				}
			});
		});

		this.updateSettings();

	}

	toggleSettings() {
		this.positionColumnSettings();
		if (this.showSettings === 'true') {
			this.showSettings = 'false';
		} else {
			this.hideAllHeaderExpands();
			this.showSettings = 'true';
		}
	}

	positionColumnSettings() {
		// add to enable positioning the column setting above the grid if necessary
		setTimeout(() => { 
			const settingsButton = this.settingsButton;
			const dropdownHeight = 510;
			const windowHeight = window.innerHeight;
			const settingsButtonTop = settingsButton.nativeElement.getBoundingClientRect().top;
			// console.log('window height', windowHeight);
			// console.log('dropdown height', dropdownHeight);
			// console.log('settingsButton top', settingsButtonTop);
			if (windowHeight - settingsButtonTop < dropdownHeight) {
			  	this.columnSettingsClass = 'position-top'
			} else {
				this.columnSettingsClass = 'position-bottom'
			}
		});
	}

	toggleSave() {
		if (this.showSave === 'true') {
			this.showSave = 'false';
		} else {
			this.hideAllHeaderExpands();
			this.showSave = 'true';
		}
	}

	toggleReset() {
		if (this.showReset === 'true') {
			this.showReset = 'false';
		} else {
			this.hideAllHeaderExpands();
			this.showReset = 'true';
		}
	}

	toggleDownload() {
		if (this.showDownload === 'true') {
			this.showDownload = 'false';
		} else {
			this.hideAllHeaderExpands();
			this.showDownload = 'true';
		}
	}

	get() {
		this.saveStateToSession();

		if (this.settings.resource) {
			const params = new ResourceParams({
				offset: this.settings.offset,
				limit: this.settings.limit,
				sort: this.getSort(),
			});
			// console.log('================ get() =======================');
			// console.log(params.sort);
			if (this.settings.resourceParams) {
				params.expand = this.settings.resourceParams.expand;
				params.total = this.settings.resourceParams.total;
				params.filters = this.settings.resourceParams.filters;
				params.urlModifiers = this.settings.resourceParams.urlModifiers;
			}

			if (this.getFilters().length > 0) {
				const filters = this.getFilters();
				
				if (params.filters) {
					const newFilters = [];
					params.filters.forEach((filter: Array<any>) => {
						const tempfilter = [];
						filter.forEach((item) => {
							tempfilter.push(item);
						});

						filters.forEach((item) => {
							item.forEach((item2) => {
								tempfilter.push(item2);
							});
						});

						newFilters.push(tempfilter);
					});
					params.filters = newFilters;
				} else {
					params.filters = filters;
				}
				
				let tempArray = <any>[];
				tempArray = params.filters[0];
				// console.log('============= params.filters - ', tempArray);
				this.filtersApplied = tempArray.length;
				}

			// if resource url does not include a variable or it does and the resourceParams include urlModifiers perform get
			// meaning if the resource url has a variable but the resourceParams do not include urlModifiers do not perform get
			// this occurs if the variable must be set by a user selection / specification before the grid can be displayed eg accountBalances
	

			if ( !this.settings.resource.url.includes(':') || (this.settings.resource.url.includes(':') && params.urlModifiers)) {
				
				if (params.sort.length === 0) {
					params.sort = this.settings.resourceParams.sort;
				}
				
				this.settings.resource
					.get(params)
					.then((data) => {
						this.settings.rows = data.items;

						this.settings.count = data.page.totalCount;
						this.settings.totals = data.page.totals;
						this.currentPage.max = this.getTotalPages();

						this.changeRows.emit(data.items);

						this.applyRowSettings();
					})
					.catch((e) => {
						console.error(e);
					});
			}		
		}
	}

	getSort() {
		const sort = [];
		// console.log('================ getSort() =================');
		// console.log(this.gridSettings.columns);
		this.gridSettings.columns.forEach((column) => {
			// console.log(column);
			// console.log(column.sortOrder);
			if (column.sortOrder) {
				sort.push({
					field: column.field,
					order: column.sortOrder || column._sortOrder,
				});
			}
		});

		return sort;
	}

	getFilters() {
		let filters = [];

		if (this.gridSettings.filters) {
			if (this.gridSettings.filters.length > 0) {
				filters = [];

				this.setFilterColumnSizes();

				this.gridSettings.filters.forEach((filter) => {
					if (filter.field.type === 'datetime') {
						if (filter.field.value) {
							const a = {
								property: filter.property,
								value:
									'"' +
									moment(filter.field.value)
										.utc()
										.format('YYYY-MM-DD HH:mm:ss') +
									'"',
								operator: 'gte',
							};
							filters.push(a);

							const b = {
								property: filter.property,
								value:
									'"' +
									moment(filter.field.value)
										.add(1, 'days')
										.utc()
										.format('YYYY-MM-DD HH:mm:ss') +
									'"',
								operator: 'lt',
							};
							filters.push(b);
						}
					} else {
						const f = {
							property: filter.property,
							operator: filter?.operator?.value?.value,
							value: (function () {
								if (filter.field) {
									if (!filter.field.hasOwnProperty('value') || filter.field.value === undefined) {
										return undefined;
									}

									if (filter.field.type === 'select') {
										return filter.field.value.id;
									}

									if (filter.field.type === 'date') {
										return (
											'"' +
											moment(filter.field.value).format('YYYY-MM-DD') +
											'"'
										);
									}

									if (typeof filter.field.value === 'string') {
										if (filter.field.value.trim() === '') {
											return undefined;
										}
									} else if (typeof filter.field.value === 'boolean') {
										if (filter.field.value === false) {
											return 'false';
										} else if (filter.field.value === true) {
											return 'true';
										}

										return undefined;
									}

									return filter.field.value;
								}

								if (!filter.value) {
									return undefined;
								}

								if (filter.value.trim() === '') {
									return undefined;
								}

								return filter.value;
							})(),
						};

						if (f.value === null) {
							f.operator = 'exists';
							f.value = false;
							filters.push(f);
						} else if (f.value !== undefined) {
							filters.push(f);
						}
					}
				});
			}
		}

		if (filters.length === 0) {
			return filters;
		} else {
			return [filters];
		}
	}

	public resetTable() {

		this.gridSettings.columns = Object.assign([], this.settings.columns);
		this.resetReportForm();
		this.resetFilters();
		this.resetSettings();
		this.toggleReset();
	}

	async createSettings() {
		const data = {
			gridId: this.settings.id,
			name: this.reportName.value,
			share: this.shareReport.value,
			userId: this.user.id,
			columns: this.gridSettings.columns,
			filters: this.gridSettings.filters,
			city: this.city.slug,
		};

		const res = await this.gridSettingsResource.post(data)

		this.toggleSave();

		this.updateSavedSettingsList();
	}

	async saveSettings() {
		const params = {
			urlModifiers: {
				id: this.selectedReport._id,
			},
			_id: this.selectedReport._id,
			gridId: this.settings.id,
			name: this.reportName.value,
			share: this.shareReport.value,
			userId: this.selectedReport.userId,
			columns: this.gridSettings.columns,
			filters: this.gridSettings.filters,
			city: this.city.slug
		};

		const data = await this.gridSettingsResource.put(params);

		this.settings.reports.value = data;
		this.selectedReport = data;

		this.toggleSave();

		await this.updateSavedSettingsList();
	}

	selectReport(e) {
		// to accomodate future changes in default grid settings after a report definition is saved
		// report settings for columns visible, column sequence, and filter criteria areapplied to grid and column settings
		// mechanisms to save a new report definition as well as update an existing definition remain unchanged
		this.resetFilters();
		this.resetSettings();
		this.selectedReport = {...e};
		this.filterErrors = [];
		if (e) {
			// apply selected report column visibility

			this.columnSettings.forEach((column) => {
				this.selectedReport.columns.forEach((reportColumn) => {
					if (column.label === reportColumn.label) {
					column.visible = reportColumn.visible;
					return;
					}
				});
			});

			// apply selected report column sequence

			const reportColumnsLabels = this.selectedReport.columns.map(({ label }) => label);
			this.columnSettings.sort((a, b) => (
				reportColumnsLabels.indexOf(a.label) - reportColumnsLabels.indexOf(b.label)
			));

			this.gridSettings.columns = [...this.columnSettings];

			// apply selected report filter settings for criteria with both an operator and a value
			// if a report filter criteria exists but field type or operator options have changed since report was saved
			// alert user to the criteria that has changed and that they need to update those specific criteria for the report

			this.gridSettings.filters.forEach((filter) => {
				this.selectedReport.filters.forEach((reportFilter) => {
					if (reportFilter.operator.value && reportFilter.field.value) {
						if (filter.property === reportFilter.property) {
							if (filter.field.type === reportFilter.field.type) {
								if (JSON.stringify(filter.operator.options) === JSON.stringify(reportFilter.operator.options)) {
									filter.operator.value = reportFilter.operator.value;
									filter.field.value = reportFilter.field.value;
									return;
								} else {
									this.filterErrors.push(filter.field.label);
								}
							} else {
								this.filterErrors.push(filter.field.label);
							};
						};
					};
				});
			});

			this.reportName.value = e.name;
			this.shareReport.value = !!e.share;

			if (e.userId === this.user.id) {
				this.shareReport.disabled = false;
			} else {
				this.shareReport.disabled = true;
			}
		} else {

			this.reportName.value = undefined;
			this.shareReport.value = undefined;
			this.shareReport.disabled = undefined;

			this.resetTable();
		}

		if (this.filterErrors.length > 0) {
			this.toggleShowReportErrorsModal();
		};

		this.get();
	}

	deleteSettings() {
		const data = {
			_id: this.selectedReport._id,
		};

		this.gridSettingsResource.delete(data).then((res) => {
			this.toggleSave();

			this.updateSavedSettingsList().then((list: any[]) => {
				this.settings.reports.value = undefined;
				this.selectedReport = undefined;
			});
		});
	}

	getCurrentUser() {
		this.currentUserResource.get().then((user) => {
			this.user = user;

			this.updateSavedSettingsList();
		});
	}

	download(type) {
		const params = new ResourceParams({
			offset: this.settings.offset,
			limit: this.settings.limit,
			sort: this.getSort(),
		});

		if (this.settings.resourceParams) {
			params.expand = this.settings.resourceParams.expand;
			params.total = this.settings.resourceParams.total;
			params.filters = this.settings.resourceParams.filters;
			params.urlModifiers = this.settings.resourceParams.urlModifiers;
		}

		if (this.getFilters().length > 0) {
			const filters = this.getFilters();

			if (params.filters) {
				const newFilters = [];
				params.filters.forEach((filter: Array<any>) => {
					const tempfilter = [];
					filter.forEach((item) => {
						tempfilter.push(item);
					});

					filters.forEach((item) => {
						item.forEach((item2) => {
							tempfilter.push(item2);
						});
					});

					newFilters.push(tempfilter);
				});

				params.filters = newFilters;
			} else {
				params.filters = filters;
			}
		}

		let url = this.settings.resource.url;

		for (const param in params.urlModifiers) {
			url = url.replace(':' + param, params.urlModifiers[param]);
		}

		const queryParams = this.dataService.buildQueryString(params);

		this.downloadResource
			.post(
				{
					url: url.toLowerCase(),
					query: queryParams,
					params: params,
					grid: this.gridSettings.columns.filter(
						(column) => column.visible === true
					),
				},
				type
			)
			.then((res: any) => {
				this.downloadFile(res.headers.location);

				this.toggleDownload();
			});
	}

	downloadFile(url) {
		// var headers = new Headers();

		// this.currentTownService.get().then( town => {

		// 	if(town){
		//    		headers.append('X-City', town.slug);
		//    	}

		//    	var options = new RequestOptions({
		//    		headers: headers
		//    	});

		this.http
			.get(url)
			.toPromise()
			.then((data: any) => {
				if (data.status === 200) {
					window.location.href = url;

					// var blob = new Blob([data.text()], { type: 'text/csv' });
					// var blob = new Blob([data.text()], { type: 'application/excel' });
					// var url= window.URL.createObjectURL(blob);
					// window.open(url)
				} else if (data.status === 404) {
					this.downloadFile(url);
				}
			})
			.catch((e) => {
				if (e.status === 404) {
					this.downloadFile(url);
				} else if (e.status === 200) {
					window.location.href = url;
				} else if (e.status === 0) {
					// window.location.href = url;
				}
			});

		// })
	}

	saveStateToSession() {
		if (!this.settings.id) {
			return false;
		}

		const data = {
			id: this.settings.id,
			columns: [],
			filters: this.gridSettings.filters,
			offset: this.settings.offset,
			limit: this.settings.limit,
		};

		this.gridSettings.columns.forEach((column) => {
			data.columns.push({ ...column });
		});

		let settings = JSON.parse(sessionStorage.getItem('gridSettings'));

		let found;
		if (settings) {
			settings.forEach((grid, key) => {
				if (grid.id === this.settings.id) {
					found = true;
					settings[key] = data;

					return false;
				}
			});
		} else {
			settings = [];
		}

		if (!found) {
			settings.push(data);
		}

		sessionStorage.setItem('gridSettings', JSON.stringify(settings));
	}

	removeStateFromSession(){
		if (this.settings.id) {
			const settings = JSON.parse(sessionStorage.getItem('gridSettings'));
			// console.log(settings);
			if (settings) {
				const settings_id = this.settings.id;
				let newSettings = settings.filter( function(gridSetting) {
					if (gridSetting.id != settings_id) {
						return gridSetting;
					}
				});
				// console.log(newSettings);
				sessionStorage.setItem('gridSettings', JSON.stringify(newSettings));
			}
		}
	}

	loadStateFromSession() {
		if (!this.settings.id) {
			return false;
		}

		const settings = JSON.parse(sessionStorage.getItem('gridSettings'));

		if (!settings) {
			return false;
		}

		let found = false;
		settings.forEach((grid) => {
			if (grid.id == this.settings.id) {
				grid.columns.forEach((value) => {
					this.gridSettings.columns.push(new GridColumn(value));
					this.columnSettings.push(new GridColumn(value));
				});

				grid.filters.forEach((value) => {
					this.gridSettings.filters.push(Object.assign({}, value));
				});

				this.settings.offset = grid.offset;
				this.settings.limit = grid.limit;

				this.currentPage.value = grid.offset ? grid.offset / grid.limit + 1 : 1;

				found = true;
				return false;
			}
		});

		return found;
	}

	loadSettings() {
		this.gridSettings.columns = [];
		this.gridSettings.filters = [];

		const tempSettings = {
			columns: [],
			filters: [],
		};

		const sessionSettings = this.loadStateFromSession();

		if (this.settings.columns) {
			this.settings.columns.forEach((value) => {
				tempSettings.columns.push(value.clone());
				this.defaultVisibility.push({label: value.label, visible: value.visible});
			});
		}

		if (this.settings.filters) {
			this.settings.filters.forEach((value) => {
				tempSettings.filters.push(Object.assign({}, value));
			});
		}

		if (!sessionSettings) {
			this.gridSettings.columns = tempSettings.columns;
			this.gridSettings.filters = tempSettings.filters;
			this.columns = tempSettings.columns;
		} else {
			// merge all these settings together
			tempSettings.columns.forEach((tempColumn) => {
				this.gridSettings.columns.forEach((gridColumn, index) => {
					if (tempColumn.label === gridColumn.label) {
						tempColumn = JSON.parse(JSON.stringify(tempColumn))
						gridColumn = JSON.parse(JSON.stringify(gridColumn))
						this.gridSettings.columns[index] = new GridColumn({ ...tempColumn, ...gridColumn });
						return;
					}
				});
			});

			tempSettings.filters.forEach((tempFilter) => {
				this.gridSettings.filters.forEach((gridFilter) => {
					if (tempFilter.property === gridFilter.property) {
						gridFilter = { ...tempFilter, ...gridFilter };
						return;
					}
				});
			});

			this.columns = this.gridSettings.columns;
		}

		this.resetSettings();
		this.toggleSettings();
	}

	isCellDisabled(column, row) {
		if (column.disabled != undefined) {
			if (column.disabled.constructor.name == 'Boolean') {
				return true;
			} else if (column.disabled.constructor.name == 'Function') {
				return column.disabled(row);
			}
		}

		return false;
	}

	getClassOf(column) {
		// console.log(column);
		if (column.visible === false) {
			return 'hidden';
		} else if ((column.format === 'currency') || (column.align === 'right')) {
			return 'right';
			} else if (column.align === 'center') {
				return 'center'
		} else {
			if (column.maxWidth) {
				switch (column.maxWidth) {
					case 300:
						return 'maxWidth300';
					case 400:
						return 'maxWidth400';
					case 500:
						return 'maxWidth500';
					case 600:
						return 'maxWidth600';
					case 700:
						return 'maxWidth700';
					case 800:
						return 'maxWidth800';
					case 900:
						return 'maxWidth900';
					case 1000:
						return 'maxWidth1000';
				}
			}
			
		}
	}

	getClassOfTotal(column) {
		if (column.visible === false) {
			return 'hidden';
		} else if (column.format === 'currency') {
			return 'rightBold';
		}
	}

	toggleShowReportErrorsModal() {
		this.showReportErrorsModal = !this.showReportErrorsModal;

		// if(!this.showReportErrorsModal){
		// 	this.resetReportForm();
		// }
	}

}
